import React from 'react';
import { graphql } from 'gatsby';

import { Layout, SEO } from 'components';
import {
  Intro,
  Image,
  Video,
  Paragraph,
  MediaParagraph,
  Outro
} from 'components/project';

import { ProjectBasic, ProjectSlices } from 'fragments';

const Project = props => {
  const doc = props.data.prismic.allProjects.edges.slice(0, 1).pop();

  if (!doc) return null;

  const metaData = {
    title: null,
    description: null,
    image: null
  };

  if (doc.node.meta_title) {
    metaData.title = doc.node.meta_title;
  } else {
    metaData.title = doc.node.title ? doc.node.title[0].text : null;
  }

  if (doc.node.meta_description) {
    metaData.description = doc.node.meta_description;
  } else {
    metaData.description = doc.node.subtitle ? doc.node.subtitle[0].text : '';
  }

  if (doc.node.meta_image) {
    metaData.image = doc.node.meta_image.url;
    metaData.meta = [
      {
        property: `og:image:width`,
        content: doc.node.meta_image.dimensions.width
      },
      {
        property: `og:image:height`,
        content: doc.node.meta_image.dimensions.height
      }
    ];
  }

  // const title = doc.node.title ? doc.node.title[0].text : 'No title';
  const slices = doc.node.body1;

  return (
    <Layout dark={true}>
      <SEO {...metaData} />

      {slices.map((slice, i) => {
        switch (slice.type) {
          case 'intro':
          case 'intro_as_video':
            return <Intro key={i} slice={slice} title={doc.node.title} />;
          case 'paragraph':
            return <Paragraph key={i} slice={slice.primary} />;
          case 'image':
            return <Image key={i} slice={slice.primary} />;
          case 'image___paragraph':
            return <MediaParagraph key={i} slice={slice.primary} />;
          case 'video':
            if (slice.primary.video) {
              return <Video key={i} slice={slice.primary} />;
            } else {
              return null;
            }
          default:
            return null;
        }
      })}

      <Outro project={doc.node} />
    </Layout>
  );
};

Project.fragments = [ProjectBasic, ProjectSlices];

export default Project;

export const query = graphql`
  query ProjectQuery($uid: String) {
    prismic {
      allProjects(uid: $uid) {
        edges {
          node {
            ...ProjectBasic
            ...ProjectSlices
            website {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            related_links {
              link_title
              link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            related_project {
              ... on PRISMIC_Project {
                ...ProjectBasic
              }
            }
          }
        }
      }
    }
  }
`;
